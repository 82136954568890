(function($) {
    let companyChannel = null;

    $.fn.getCompanyChannel = function () {
        return companyChannel;
    }

    $.fn.pad = function (str, padStr = "0", max) {
        str = str.toString();
        return str.length < max ? $.fn.pad(padStr + str, padStr, max) : str;
    }

    select2 = function() {
        let $select = $("select.display");
        $select.each(function () {
            let $this = $(this);
            $this.select2({
                tags: false,
                theme: "bootstrap4",
                width: $this.data('width') ? $this.data('width') : $this.hasClass('w-100') ? '100%' : 'style',
                placeholder: $this.data('placeholder'),
                allowClear: Boolean($this.data('allow-clear')),
            });
        });

        $select.on("select2:select", function (evt) {
            let $element = $(evt.params.data.element);
            let $this = $(this);
            $element.detach();
            $this.append($element);
            $this.trigger("change");
        });
    };

    updateCheckbox = function (checkbox, newValue) {
        checkbox.prop('checked', !!newValue).val(newValue ? 1 : 0);
    };

    pageLoaderShow = function (message) {
        let loaderMessage = $("#loader-message");
        if (message) {
            loaderMessage.text(message);
        } else {
            loaderMessage.text(loaderMessage.data('default'));
        }

        $("#page-loader").show();
    };
    pageLoaderHide = function () {
        let message = $("#loader-message");
        message.text(message.data('default'));
        $("#page-loader").hide();
    };

    $(document).ready(function () {
        let companyChannelId = $('input#company-channel-id').val();
        let operatingPlanGroupId = $('input#operating-plan-group-id').val();
        if (companyChannelId) {
            companyChannel = Echo.channel(`company.${companyChannelId}`);
        }

        if (companyChannel) {
            if (operatingPlanGroupId) {
                companyChannel.listen('ProductionItemInProgress', (e) => {
                    // noinspection EqualityComparisonWithCoercionJS
                    if (operatingPlanGroupId == e.planGroupId && e.path !== window.location.href) {
                        $(location).attr('href', e.path);
                    }
                });
            }

            companyChannel.listen('ScanAlert', (e) => {
                // noinspection EqualityComparisonWithCoercionJS
                if (!operatingPlanGroupId || (e.planGroupId && operatingPlanGroupId == e.planGroupId)) {
                    let alert = $('#' + e.type);
                    if (e.deviceName) {
                        alert.find('#alert-for-device-name').text(e.deviceName);
                    }

                    alert.toast('show');
                }
            });
        }

        $(function () {
            $('[data-toggle="tooltip"]').tooltip()
        });

        $(function () {
            $('[data-toggle="popover"]').popover()
        })

        $('.toast').not('.hide').toast('show');

        $.extend( $.fn.dataTable.defaults, {
            searching: false,
            ordering:  false,
            info:  false,
            paging:  false,
            lengthChange:  false,
        } );

        let dtLangs = {
            fr: {
                processing: "Traitement en cours...",
                search: "Rechercher&nbsp;:",
                lengthMenu: "Afficher _MENU_ &eacute;l&eacute;ments",
                info: "Affichage de l'&eacute;lement _START_ &agrave; _END_ sur _TOTAL_ &eacute;l&eacute;ments",
                infoEmpty: "Affichage de l'&eacute;lement 0 &agrave; 0 sur 0 &eacute;l&eacute;ments",
                infoFiltered: "(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)",
                infoPostFix: "",
                loadingRecords: "Chargement en cours...",
                zeroRecords: "Aucun &eacute;l&eacute;ment &agrave; afficher",
                paginate: {
                    first: "Premier",
                    previous: "Pr&eacute;c&eacute;dent",
                    next: "Suivant",
                    last: "Dernier"
                },
                aria: {
                    sortAscending: ": activer pour trier la colonne par ordre croissant",
                    sortDescending: ": activer pour trier la colonne par ordre décroissant"
                }
            }
        };

        let langCode = $("html").attr('lang');
        let locale = dtLangs[langCode];
        $('table.data-table').each(function () {
            let table = $(this);

            if (!locale) {
                locale = {};
            }

            table.DataTable({
                paging: table.data('paging') ? table.data('paging') : false,
                ordering: table.data('ordering') ? table.data('ordering') : false,
                info: table.data('info') ? table.data('info') : false,
                searching: table.data('searching') ? table.data('searching') : false,
                lengthChange: table.data('length-change') ? table.data('length-change') : false,
                pageLength: table.data('page-length') ? table.data('page-length') : 10,
                rowReorder: table.data('row-reorder') ? {selector: 'tr'} : false,
                columnDefs: [
                    table.data('all-centered') ? {className: "text-center", targets: '_all'} : '',
                ],
                language: {
                    ...locale,
                    ...{
                        emptyTable: table.data('empty-table')
                    }
                }
            })
        });

        select2();

        let checkBoxes = $("input[type='checkbox']");
        checkBoxes.each(function () {
            let $this = $(this);
            $this.prop('checked', $this.val() === '1');
        });
        checkBoxes.on("change", function () {
            let $this = $(this);
            $this.val($this.is(':checked') ? 1 : 0);
        });
    });

    // Add active state to sidbar nav links
    var path = window.location.href; // because the 'href' property of the DOM element is the absolute path
        $("#layoutSidenav_nav .sb-sidenav a.nav-link").each(function() {
            if ($(this).attr('href') === path) {
                $(this).addClass("active");
            }
        });

    // Toggle the side navigation
    $("#sidebarToggle").on("click", function(e) {
        e.preventDefault();
        $("body").toggleClass("sb-sidenav-toggled");
    });
})(jQuery);
