require('./bootstrap');
require('datatables.net-dt');
require('datatables.net-bs4');
require('datatables.net-rowreorder-dt');
require('datatables.net-rowreorder-bs4');
require('datatables.net-responsive-bs4');
global.moment = require('moment');
require('tempusdominus-bootstrap-4');
require('select2/dist/js/select2.full.min');
require('bootstrap-touchspin');
require('./scripts');
